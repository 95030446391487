import React from 'react';
import PropTypes from 'prop-types';
import { getImage } from 'gatsby-plugin-image';

import FullWidthImage from '../FullWidthImage';
import YogaGrid from '../YogaGrid';
import YogaPlan from '../YogaPlan';
import Newsletter from '../Newsletter';

const InvestPageTemplate = ({ image, title, items, practice, newsletter }) => {
  const heroImage = getImage(image) || image;
  return (
    <div className="content">
      <FullWidthImage img={heroImage} title={title} />
      <section className="section section--gradient">
        <div className="container">
          <div className="section">
            <YogaGrid gridItems={items} />
            <YogaPlan plan={practice} />
            <Newsletter newsletter={newsletter} />
          </div>
        </div>
      </section>
    </div>
  );
};

InvestPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  items: PropTypes.array,
  practice: PropTypes.object,
  newsletter: PropTypes.object,
};

export default InvestPageTemplate;
