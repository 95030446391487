import React from 'react';
import PropTypes from 'prop-types';

const Newsletter = ({ newsletter }) => (
  <section className="section has-background-link mt-5 py-6">
    <div className="is-flex is-flex-direction-column is-align-items-center">
      <h1 className="has-text-white is-size-1	is-capitalized has-text-centered">
        {newsletter.title}
      </h1>
      <h6 className="has-text-white is-size-5	is-capitalized has-text-centered">
        {newsletter.heading}
      </h6>
      <h5 className="has-text-white is-size-6	is-capitalized has-text-weight-normal has-text-centered">
        {newsletter.description}
      </h5>
      <form className="is-flex" method="post">
        <input
          type="email"
          name="EMAIL"
          placeholder={newsletter.placeholder}
          required=""
        />
        <input type="submit" value="Submit" />
      </form>
    </div>
  </section>
);

Newsletter.propTypes = {
  newsletter: PropTypes.shape({
    title: PropTypes.string,
    heading: PropTypes.string,
    description: PropTypes.string,
    placeholder: PropTypes.string,
  }),
};

export default Newsletter;
