import React from 'react';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';

const YogaGrid = ({ gridItems }) => (
  <div className="columns">
    {map(gridItems, item => (
      <div key={item.title} className="column">
        <div className="is-flex is-flex-direction-column is-align-items-center">
          <PreviewCompatibleImage imageInfo={{ image: item.icon }} />
          <h6 className="mt-5 is-size-5	has-text-centered">{item.title}</h6>
          <div>
            <p className="has-text-centered	">{item.description}</p>
          </div>
        </div>
      </div>
    ))}
  </div>
);

YogaGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      description: PropTypes.string,
    }),
  ),
};

export default YogaGrid;
