import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import InvestPageTemplate from '../components/invest/InvestTemplate';

const InvestPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout>
      <InvestPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        items={frontmatter.items}
        practice={frontmatter.practice}
        newsletter={frontmatter.newsletter}
      />
    </Layout>
  );
};
InvestPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};
export default InvestPage;

export const pageQuery = graphql`
  query InvestPageTemplate($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        items {
          title
          icon {
            childImageSharp {
              gatsbyImageData(width: 96, quality: 100, layout: FIXED)
            }
          }
          description
        }
        practice {
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: CONSTRAINED)
            }
          }
          title
          description
          learn
        }
        newsletter {
          title
          heading
          description
          placeholder
        }
      }
    }
  }
`;
