import React from 'react';
import PropTypes from 'prop-types';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';

const YogaPlan = ({ plan }) => (
  <div className="columns mt-5 mb-0 mx-0">
    <div className="wrapped-plan column is-half p-5">
      <div className="m-4">
        <h1 className="is-size-1 mt-3	pb-1">{plan.title}</h1>
        <div>
          <p className="mb-5">{plan.description}</p>
        </div>
        <button className="bttn prime">
          <div className="hover-flip">
            <span className="first">{plan.learn}</span>
            <span className="second">{plan.learn}</span>
          </div>
        </button>
      </div>
    </div>
    <div className="column is-half p-0">
      <div className="is-flex" style={{ height: '100%' }}>
        <PreviewCompatibleImage imageInfo={plan} />
      </div>
    </div>
  </div>
);

YogaPlan.propTypes = {
  plan: PropTypes.shape({
    title: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    description: PropTypes.string,
    learn: PropTypes.string,
  }),
};

export default YogaPlan;
